@import "../variables.scss";

.bigMenu * {
    font-family: 'Nunito', sans-serif;
    color: $color2;
}

.bigMenu {
    width: 100%;
    height: 100%;
    overflow: auto;

    background-color: $color1;
}

.bigMenuTitle {
    display: flex;
    width: 100%;
    padding: 20px 0px;
}

.bigMenuTitle>div {
    margin: auto;
    font-size: 40px;
}

.bigMenuContentPC {
    display: none;
}

.bigMenuContentPC>div {
    height: 100%;
    width: 100%;
}

.bigMenuContentPhone {
    display: flex;
    flex-direction: column;
    background: $color1;
    margin: 0 5%;
    height: auto;
    padding: 10vh 0;
}

 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .bigMenuContentPC{ display: flex; }
    .bigMenuContentPhone { display: none; }
 }

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .bigMenuTitle>div { font-size: 35px; }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .bigMenuTitle>div { font-size: 45px; }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .bigMenuTitle>div { font-size: 55px; }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .bigMenuTitle>div { font-size: 65px; }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .bigMenuTitle>div { font-size: 75px; }
 }





 .leftMenu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0;
    overflow: auto;
    padding: 2vmin 0;
}

.leftMenu>div {
    margin: auto 0;
}

.main {
    display: flex;

    width: 100%;
    height: 100%;
}

.menu {
    display: none;
    flex-direction: column;
    background: $color1;
    width: 25vw;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .menu {display: flex;}
}

.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: $color4;

    padding: 2vmin 0;
}

.page>div {
    padding: 0 2vmin;
}

.pageContent {
    overflow: auto;
    height: 0;
    flex-grow: 1;
}


.separator {
    border-bottom: white solid 2px;
    margin: 10px;
}
