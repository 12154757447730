.ipv4 {
    display: flex;
    border: white solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    height: 50px;
}

.ipv4>p {
    flex-grow: 1;
    width: auto;
    margin: auto;
}

.ipv4>div {
    margin: auto;
}

.ipv4>div>input {
    color: black;
    margin: 0 5px;
}