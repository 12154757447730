$color1: #00457C;
$color2: #008A5F;
$color3: #666666;

$color4: #666666;
$color5: #666666;

$colorext1: #DAD1C7;
$colorext2: #867A77;
$colorext3: #CA722B;
$colorext4: #F7B532;
$colorext4: #BE2025;


// Adobe XD Colors

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

$colorText: #FFFFFF;
$colorShadow: #000000;

$color1: #0D1B2A;
$color2: #E0E1DD;
$color3: #08101A;
$color4: #1B263B;
