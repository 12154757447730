.text {
    display: flex;
    border: white solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    height: 50px;
}

.text>p {
    flex-grow: 1;
    width: auto;
    margin: auto;
}

.text>div {
    margin: auto;
}

.text>div>input {
    color: black;
    font-size: 20px;
}