.info {
    display: flex;
    border: white solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    height: 50px;
}

.info>p {
    flex-grow: 1;
    width: auto;
    margin: auto;
}

.info>div {
    margin: auto;
    text-align: right;
}