@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
/* purgecss start ignore */

.App{
  text-align:center;
  background-image:"background.png"
}

.App-logo{
  height:40vmin;
  pointer-events:none
}

@media (prefers-reduced-motion: no-preference){
  .App-logo{
    -webkit-animation:App-logo-spin infinite 20s linear;
    animation:App-logo-spin infinite 20s linear
  }
}

.App-header{
  background:#282c34;
  min-height:100vh;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-align-items:center;
  align-items:center;
  -webkit-justify-content:center;
  justify-content:center;
  font-size:calc(10px + 2vmin);
  color:white
}

.App-link{
  color:#61dafb
}

@-webkit-keyframes App-logo-spin{
  from{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }

  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

@keyframes App-logo-spin{
  from{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }

  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettings_bigMenu__1WTp3 *{
  font-family:'Nunito', sans-serif;
  color:#E0E1DD
}

.StartingamesSettings_bigMenu__1WTp3{
  width:100%;
  height:100%;
  overflow:auto;
  background-color:#0D1B2A
}

.StartingamesSettings_bigMenuTitle__34su3{
  display:-webkit-flex;
  display:flex;
  width:100%;
  padding:20px 0px
}

.StartingamesSettings_bigMenuTitle__34su3>div{
  margin:auto;
  font-size:40px
}

.StartingamesSettings_bigMenuContentPC__2_lkw{
  display:none
}

.StartingamesSettings_bigMenuContentPC__2_lkw>div{
  height:100%;
  width:100%
}

.StartingamesSettings_bigMenuContentPhone__2APYE{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  background:#0D1B2A;
  margin:0 5%;
  height:auto;
  padding:10vh 0
}

@media only screen and (min-width: 768px){
  .StartingamesSettings_bigMenuContentPC__2_lkw{
    display:-webkit-flex;
    display:flex
  }

  .StartingamesSettings_bigMenuContentPhone__2APYE{
    display:none
  }
}

@media only screen and (max-width: 600px){
  .StartingamesSettings_bigMenuTitle__34su3>div{
    font-size:35px
  }
}

@media only screen and (min-width: 600px){
  .StartingamesSettings_bigMenuTitle__34su3>div{
    font-size:45px
  }
}

@media only screen and (min-width: 768px){
  .StartingamesSettings_bigMenuTitle__34su3>div{
    font-size:55px
  }
}

@media only screen and (min-width: 992px){
  .StartingamesSettings_bigMenuTitle__34su3>div{
    font-size:65px
  }
}

@media only screen and (min-width: 1200px){
  .StartingamesSettings_bigMenuTitle__34su3>div{
    font-size:75px
  }
}

.StartingamesSettings_leftMenu__GKM3p{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-flex-grow:1;
  flex-grow:1;
  height:0;
  overflow:auto;
  padding:2vmin 0
}

.StartingamesSettings_leftMenu__GKM3p>div{
  margin:auto 0
}

.StartingamesSettings_main__2NVfY{
  display:-webkit-flex;
  display:flex;
  width:100%;
  height:100%
}

.StartingamesSettings_menu__3_BU0{
  display:none;
  -webkit-flex-direction:column;
  flex-direction:column;
  background:#0D1B2A;
  width:25vw
}

@media only screen and (min-width: 768px){
  .StartingamesSettings_menu__3_BU0{
    display:-webkit-flex;
    display:flex
  }
}

.StartingamesSettings_page__3XY21{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-flex-grow:1;
  flex-grow:1;
  background:#1B263B;
  padding:2vmin 0
}

.StartingamesSettings_page__3XY21>div{
  padding:0 2vmin
}

.StartingamesSettings_pageContent__2dKjJ{
  overflow:auto;
  height:0;
  -webkit-flex-grow:1;
  flex-grow:1
}

.StartingamesSettings_separator__1GABT{
  border-bottom:white solid 2px;
  margin:10px
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsLinkBar_linkBar__2oS1U{
  display:-webkit-flex;
  display:flex;
  margin-bottom:10px;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}

.StartingamesSettingsLinkBar_linkBarButton__2v94L{
  font-size:40px;
  padding:0 15px;
  cursor:pointer
}

.StartingamesSettingsLinkBar_linkBarButton__2v94L:hover{
  opacity:0.75
}

.StartingamesSettingsLinkBar_linkBar__2oS1U>div{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column
}

.StartingamesSettingsLinkBar_linkBarL1__3rnMD{
  display:-webkit-flex;
  display:flex;
  font-size:15px;
  cursor:pointer;
  z-index:1
}

.StartingamesSettingsLinkBar_linkBarL1__3rnMD>div::after{
  content:" / ";
  cursor:default
}

.StartingamesSettingsLinkBar_linkBarL1__3rnMD>:last-child:after{
  content:""
}

.StartingamesSettingsLinkBar_linkBarL2__70xVg{
  font-size:30px;
  margin-top:-10px
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsMenus_bigMenuContent__8l6Ad{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-justify-content:space-evenly;
  justify-content:space-evenly;
  -webkit-align-items:stretch;
  align-items:stretch
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ{
  padding:15px 0px;
  width:80%;
  cursor:pointer;
  background-color:#0D1B2A;
  border-radius:30px;
  box-shadow:1px 1px 15px #000;
  margin:0 auto;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  transition:all ease-in-out 0.5s
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ>.StartingamesSettingsMenus_logo__2XBYK{
  width:25%;
  margin:auto;
  fill:#E0E1DD
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ>.StartingamesSettingsMenus_title__25Wj3{
  margin:auto;
  padding:5px 0px;
  font-size:4vh
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ>.StartingamesSettingsMenus_desc__Rp1VO{
  margin:auto;
  padding:5px 0px;
  font-size:2vh
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ:hover{
  background:#08101A
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ:hover .StartingamesSettingsMenus_logo__2XBYK{
  fill:white
}

.StartingamesSettingsMenus_bigMenuButton__3ShXQ:hover .StartingamesSettingsMenus_title__25Wj3{
  color:white
}

.StartingamesSettingsMenus_bigMenuButtonWrapper__1S0uC{
  display:-webkit-flex;
  display:flex;
  padding:10px 0px
}

@media only screen and (max-width: 600px){
  .StartingamesSettingsMenus_bigMenuButtonWrapper__1S0uC{
    width:100%
  }
}

@media only screen and (min-width: 600px){
  .StartingamesSettingsMenus_bigMenuButtonWrapper__1S0uC{
    width:75%
  }
}

@media only screen and (min-width: 768px){
  .StartingamesSettingsMenus_bigMenuButtonWrapper__1S0uC{
    width:50%
  }
}

@media only screen and (min-width: 992px){
  .StartingamesSettingsMenus_bigMenuButtonWrapper__1S0uC{
    width:33%
  }
}

@media only screen and (min-width: 1200px){
  .StartingamesSettingsMenus_bigMenuButtonWrapper__1S0uC{
    width:33%
  }
}

.StartingamesSettingsMenus_bar__3v4oS{
  height:3px;
  border-radius:100px;
  width:0%;
  margin-left:2%;
  background:white
}

.StartingamesSettingsMenus_link__27e4k:hover>.StartingamesSettingsMenus_bar__3v4oS{
  -webkit-animation:StartingamesSettingsMenus_barAnimation__3HW-r 0.17s linear normal forwards;
  animation:StartingamesSettingsMenus_barAnimation__3HW-r 0.17s linear normal forwards
}

@-webkit-keyframes StartingamesSettingsMenus_barAnimation__3HW-r{
  0%{
    width:0%
  }

  100%{
    width:75%
  }
}

@keyframes StartingamesSettingsMenus_barAnimation__3HW-r{
  0%{
    width:0%
  }

  100%{
    width:75%
  }
}

.StartingamesSettingsMenus_link__27e4k{
  cursor:pointer;
  padding:15px;
  background:#0D1B2A;
  color:#E0E1DD;
  transition:all ease-in-out 0.5s
}

.StartingamesSettingsMenus_content__181dE{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:row;
  flex-direction:row;
  grid-gap:10px;
  gap:10px
}

.StartingamesSettingsMenus_link__27e4k>.StartingamesSettingsMenus_content__181dE>.StartingamesSettingsMenus_logo__2XBYK{
  width:15%
}

.StartingamesSettingsMenus_logo__2XBYK{
  fill:#E0E1DD;
  position:relative;
  height:100%
}

.StartingamesSettingsMenus_logo__2XBYK:before{
  content:'';
  display:block;
  padding-bottom:100%
}

.StartingamesSettingsMenus_logo__2XBYK>div{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:-webkit-flex;
  display:flex;
  overflow:hidden
}

.StartingamesSettingsMenus_logo__2XBYK>div>div{
  margin:auto;
  display:-webkit-flex;
  display:flex
}

.StartingamesSettingsMenus_logo__2XBYK>div>div>*{
  height:95%;
  width:95%;
  object-fit:contain
}

.StartingamesSettingsMenus_title__25Wj3{
  margin:auto auto auto 0
}

.StartingamesSettingsMenus_link__27e4k.StartingamesSettingsMenus_active__2Dy0s,.StartingamesSettingsMenus_link__27e4k:hover,.StartingamesSettingsMenus_link2__2yYLk.StartingamesSettingsMenus_active__2Dy0s,.StartingamesSettingsMenus_link2__2yYLk:hover{
  background:#08101A
}

.StartingamesSettingsMenus_link__27e4k.StartingamesSettingsMenus_active__2Dy0s,.StartingamesSettingsMenus_link__27e4k:hover>.StartingamesSettingsMenus_content__181dE>.StartingamesSettingsMenus_logo__2XBYK{
  fill:white
}

.StartingamesSettingsMenus_link__27e4k.StartingamesSettingsMenus_active__2Dy0s,.StartingamesSettingsMenus_link__27e4k:hover>.StartingamesSettingsMenus_content__181dE>.StartingamesSettingsMenus_title__25Wj3{
  color:white
}

.StartingamesSettingsMenus_link__27e4k>title{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsPage_link__258BR{
  cursor:pointer;
  padding:0 5px;
  margin-bottom:10px;
  background:#1B263B;
  color:#E0E1DD;
  border-radius:15px;
  box-shadow:1px 1px 10px #000;
  transition:all ease-in-out 0.5s
}

.StartingamesSettingsPage_link__258BR:hover{
  -webkit-animation:StartingamesSettingsPage_subLinkAnimation__3ga-0 0.25s linear alternate both;
  animation:StartingamesSettingsPage_subLinkAnimation__3ga-0 0.25s linear alternate both
}

@-webkit-keyframes StartingamesSettingsPage_subLinkAnimation__3ga-0{
  0%{
    border-radius:15px
  }

  100%{
    border-radius:30px
  }
}

@keyframes StartingamesSettingsPage_subLinkAnimation__3ga-0{
  0%{
    border-radius:15px
  }

  100%{
    border-radius:30px
  }
}

.StartingamesSettingsPage_link__258BR>.StartingamesSettingsPage_content__2vvCh>.StartingamesSettingsPage_logo__3x-m_{
  width:50px;
  margin:5px
}

.StartingamesSettingsPage_content__2vvCh{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:row;
  flex-direction:row;
  grid-gap:5px;
  gap:5px
}

.StartingamesSettingsPage_logo__3x-m_{
  fill:#E0E1DD;
  position:relative;
  height:100%
}

.StartingamesSettingsPage_logo__3x-m_:before{
  content:'';
  display:block;
  padding-bottom:100%
}

.StartingamesSettingsPage_logo__3x-m_>div{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:-webkit-flex;
  display:flex;
  overflow:hidden
}

.StartingamesSettingsPage_logo__3x-m_>div>div{
  margin:auto;
  display:-webkit-flex;
  display:flex
}

.StartingamesSettingsPage_logo__3x-m_>div>div>*{
  height:95%;
  width:95%;
  object-fit:contain
}

.StartingamesSettingsPage_title__29Jkr{
  margin:auto auto auto 0
}

.StartingamesSettingsPage_link__258BR.StartingamesSettingsPage_active__3lkWR,.StartingamesSettingsPage_link__258BR:hover{
  background:#08101A
}

.StartingamesSettingsPage_link__258BR.StartingamesSettingsPage_active__3lkWR,.StartingamesSettingsPage_link__258BR:hover>.StartingamesSettingsPage_content__2vvCh>.StartingamesSettingsPage_logo__3x-m_{
  fill:white
}

.StartingamesSettingsPage_link__258BR.StartingamesSettingsPage_active__3lkWR,.StartingamesSettingsPage_link__258BR:hover>.StartingamesSettingsPage_content__2vvCh>.StartingamesSettingsPage_title__29Jkr{
  color:white
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsSwitch_switch__1Ogh8{
  display:-webkit-flex;
  display:flex;
  border:white solid 1px;
  border-radius:5px;
  padding:5px;
  margin-bottom:10px;
  height:50px
}

.StartingamesSettingsSwitch_switch__1Ogh8>p{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

.StartingamesSettingsSwitch_switch__1Ogh8>div{
  margin:auto
}

.StartingamesSettingsSwitch_button__3GuwU{
  position:relative;
  display:-webkit-flex;
  display:flex;
  width:60px;
  height:34px
}

.StartingamesSettingsSwitch_button__3GuwU input{
  opacity:0;
  width:0;
  height:0
}

.StartingamesSettingsSwitch_slider__ehqP-{
  position:absolute;
  cursor:pointer;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#ccc;
  transition:.4s
}

.StartingamesSettingsSwitch_slider__ehqP-:before{
  position:absolute;
  content:"";
  height:26px;
  width:26px;
  left:4px;
  bottom:4px;
  background-color:white;
  transition:.4s
}

input:checked+.StartingamesSettingsSwitch_slider__ehqP-{
  background-color:#2196F3
}

input:focus+.StartingamesSettingsSwitch_slider__ehqP-{
  box-shadow:0 0 1px #2196F3
}

input:checked+.StartingamesSettingsSwitch_slider__ehqP-:before{
  -webkit-transform:translateX(26px);
  transform:translateX(26px)
}

.StartingamesSettingsSwitch_slider__ehqP-.StartingamesSettingsSwitch_round__2EPr_{
  border-radius:34px
}

.StartingamesSettingsSwitch_slider__ehqP-.StartingamesSettingsSwitch_round__2EPr_:before{
  border-radius:50%
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsSelect_select__Mt7_b{
  display:-webkit-flex;
  display:flex;
  border:white solid 1px;
  border-radius:5px;
  padding:5px;
  margin-bottom:10px;
  height:50px
}

.StartingamesSettingsSelect_select__Mt7_b>p{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

.StartingamesSettingsSelect_select__Mt7_b>div{
  margin:auto
}

.StartingamesSettingsSelect_selectSelect__zjsS3{
  color:black;
  font-size:20px
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsIPv4_ipv4__3datl{
  display:-webkit-flex;
  display:flex;
  border:white solid 1px;
  border-radius:5px;
  padding:5px;
  margin-bottom:10px;
  height:50px
}

.StartingamesSettingsIPv4_ipv4__3datl>p{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

.StartingamesSettingsIPv4_ipv4__3datl>div{
  margin:auto
}

.StartingamesSettingsIPv4_ipv4__3datl>div>input{
  color:black;
  margin:0 5px
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsNumber_number__18OkZ{
  display:-webkit-flex;
  display:flex;
  border:white solid 1px;
  border-radius:5px;
  padding:5px;
  margin-bottom:10px;
  height:50px
}

.StartingamesSettingsNumber_number__18OkZ>p{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

.StartingamesSettingsNumber_number__18OkZ>div{
  margin:auto
}

.StartingamesSettingsNumber_number__18OkZ>div>input{
  color:black;
  font-size:20px
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsText_text__2NIxR{
  display:-webkit-flex;
  display:flex;
  border:white solid 1px;
  border-radius:5px;
  padding:5px;
  margin-bottom:10px;
  height:50px
}

.StartingamesSettingsText_text__2NIxR>p{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

.StartingamesSettingsText_text__2NIxR>div{
  margin:auto
}

.StartingamesSettingsText_text__2NIxR>div>input{
  color:black;
  font-size:20px
}

/* purgecss end ignore */

/* purgecss start ignore */

.StartingamesSettingsInfo_info__2XwRr{
  display:-webkit-flex;
  display:flex;
  border:white solid 1px;
  border-radius:5px;
  padding:5px;
  margin-bottom:10px;
  height:50px
}

.StartingamesSettingsInfo_info__2XwRr>p{
  -webkit-flex-grow:1;
  flex-grow:1;
  width:auto;
  margin:auto
}

.StartingamesSettingsInfo_info__2XwRr>div{
  margin:auto;
  text-align:right
}

/* purgecss end ignore */

